var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{class:[
    'expand-card-tile overflow-hidden white',
    { 'expand-card-tile_expanded': _vm.expandedContent },
  ]},[_c('header',{staticClass:"position-relative"},[(_vm.expandClickFullBlock)?_c('button',{staticClass:"expand-card-tile__header-layer",on:{"click":function($event){return _vm.toggleExpandContent()}}}):_vm._e(),_c('div',{class:[
        'expand-card-tile__header d-flex flex-column justify-center pa-4 text-caption-1',
        {
          'pointer-events-none': _vm.expandClickFullBlock,
        },
      ]},[_vm._t("header",function(){return [_vm._t("header-prepend-inner",null,{"item":_vm.item}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1 flex-shrink-1"},[_vm._t("header-title",null,{"item":_vm.item})],2),_c('v-btn',{staticClass:"expand-card-tile__header-arrow flex-shrink-0",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.toggleExpandContent()}}},[_c('v-icon',{attrs:{"size":"15","color":"grey darken-1"}},[_vm._v(" $arrowDropDown ")])],1)],1)]})],2)]),_c('transition',{attrs:{"name":"expand-card-tile__content"}},[(_vm.expandedContent)?_c('div',{staticClass:"expand-card-tile__content"},[_vm._t("item",function(){return [_vm._l((_vm.headers),function(header){return [(_vm.validateHeader(header, _vm.item))?_c('div',{key:header.value,class:[
              'expand-card-tile__row d-flex flex-wrap align-center px-4',
              {
                'py-3': header.text,
                'py-4': !header.text,
              },
            ]},[(header.text)?_c('div',{staticClass:"expand-card-tile__label flex-grow-1 font-weight-bold"},[_vm._t(`header.${header.value}`,function(){return [_vm._v(" "+_vm._s(header.text)+" ")]},{"header":header})],2):_vm._e(),_c('div',{class:[
                'expand-card-tile__value text-break',
                { 'flex-grow-1': !header.text },
              ],style:({ width: header.full ? '100%' : undefined })},[_vm._t(`item.${header.value}`,function(){return [_vm._v(" "+_vm._s(_vm.item[header.value])+" ")]},{"item":_vm.item,"header":header})],2),_vm._t(`item.${header.value}:append`,null,{"item":_vm.item,"header":header})],2):_vm._e()]})]})],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }