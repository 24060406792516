import { DataTableHeader } from "vuetify";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ExpandCardTile extends Vue {
  @Prop({ type: Boolean, default: true })
  private readonly expandClickFullBlock!: boolean;
  @Prop({ type: Array, required: true })
  private readonly headers!: DataTableHeader[];
  @Prop({ type: Object, required: true }) private readonly item!: Record<
    string,
    unknown
  >;

  private expandedContent = false;

  private validateHeader(
    header: DataTableHeader,
    item: Record<string, unknown>
  ) {
    const itemValue = item[header.value];

    if (header.filter && !header.filter(itemValue, null, item)) {
      return false;
    }

    return (
      ![undefined, null].some((value) => value === itemValue) ||
      !Object.hasOwnProperty.call(item, header.value)
    );
  }

  private toggleExpandContent(expanded = !this.expandedContent) {
    this.expandedContent = expanded;
  }
}
